



















import { Component, Vue } from "vue-property-decorator";
import { DefaTransitionFadeOutIn } from "@defa-as/components";
import PasswordRecoveryUsernameForm from "@/components/password-recovery/PasswordRecoveryUsernameForm.vue";
import { ROUTE_NAMES } from "@/router";
import { userModule } from "@/store/modules/user-module";

@Component({
  components: {
    PasswordRecoveryUsernameForm,
    DefaTransitionFadeOutIn,
  },
})
export default class ViewPasswordRecovery extends Vue {
  emailSent = false;
  email = "";

  get loginRoute() {
    return {
      name: ROUTE_NAMES.LOGIN,
    };
  }

  async onSubmit({ email }: { email: string }) {
    this.email = email;
    await userModule.recoverPassword({ email });
    this.emailSent = true;
  }
}
