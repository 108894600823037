



























import { Component, Vue } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import { DefaBaseButton } from "@defa-as/components";
import PasswordInput from "@/components/inputs/PasswordInput.vue";
import UsernameInput from "@/components/inputs/UsernameInput.vue";
import { loadingModule } from "@/store/modules/loading-module";

@Component({
  components: {
    ValidationObserver,
    DefaBaseButton,
    UsernameInput,
    PasswordInput,
  },
})
export default class PasswordRecoveryUsernameForm extends Vue {
  email = "";

  get loadingModule() {
    return loadingModule;
  }

  async onSubmit() {
    this.$emit("submit", { email: this.email });
  }
}
